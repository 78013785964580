/*!* ------------------------------------ *\*/
/*  ICONS*/
/*\* ------------------------------------ *!*/

[class^="ri-"].icon-left {
  margin-right: .5rem;
}

[class^="ri-"].icon-right {
  margin-left: .5rem;
}

.icon-xl::before {
  --icon-size: 4rem !important;
}
